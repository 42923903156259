// Libs
import React from 'react';
import { navigate } from 'gatsby';

// Components
import SEO from '../../components/seo';
import HeaderPetshopRegister from '../../components/HeaderPetshopRegister';

import TermsOfUse from '../../components/TermsOfUse';

function TermsOfUserPage() {
  const handleSubmitForm = () => {
    navigate('/');
  };

  return (
    <>
      <SEO title="animAU | Termos de uso" />

      <HeaderPetshopRegister percentProgressLine="100%" />
      <TermsOfUse handleSubmitForm={handleSubmitForm} />
    </>
  );
}

export default TermsOfUserPage;